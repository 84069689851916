import React, { useState } from "react";
import Button from "../button/Button";

function About(props) {
 const [dataImg] = useState([
  {
   id: 1,
   img: "/images/layouts/about-01.png",
   class: "img1",
  },
  {
   id: 2,
   img: "/images/layouts/about-02.png",
   class: "img2",
  },
  {
   id: 3,
   img: "/images/layouts/about-03.png",
   class: "img3",
  },
  {
   id: 4,
   img: "/images/layouts/about-04.png",
   class: "img4",
  },
  {
   id: 5,
   img: "/images/layouts/about-05.png",
   class: "img5",
  },
 ]);

 const [dataBlock] = useState({
  subheading: "About us",
  heading: "DEVELOPING AUTOMATION WITH 6G",
  desc1:
   "Thermal applies 6G technology at Blockchain to increase interoperability through the power of 6G.",
  desc2:
   "We develop an ecosystem that extends the supply chain at Blockchain. By developing the potential of new technology and the connection with Blockchain, we can create new breakthroughs in the practical application of Blockchain.",
 });
 return (
  <section className="about">
   <div className="shape"></div>
   <div className="container">
    <div className="row rev">
     <div className="col-xl-6 col-md-12">
      <div className="about__right">
       <div className="images">
        {dataImg.map((idx) => (
         <img key={idx.id} className={idx.class} src={idx.img} alt="thermal" />
        ))}
       </div>
      </div>
     </div>
     <div className="col-xl-6 col-md-12">
      <div className="block-text">
       <h6 className="sub-heading">
        <span>{dataBlock.subheading}</span>
       </h6>
       <h3 className="heading">{dataBlock.heading}</h3>
       <p className="mb-17">{dataBlock.desc1}</p>
       <p className="mb-26">{dataBlock.desc2}</p>
       <Button link="/about" title="More About Us" />
      </div>
     </div>
    </div>
   </div>
  </section>
 );
}

export default About;
