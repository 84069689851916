const menus = [
 {
  id: 2,
  name: "News",
  links: "/news",
 },
 {
  id: 3,
  name: "NFTs",
  links: "/nfts",
 },
 {
  id: 4,
  name: "Visions & Mission",
  links: "/visions-mission",
 },
 {
  id: 5,
  name: "Advisors & Backers",
  links: "/advisors-backers",
 },
 {
  id: 6,
  name: "RoadMap",
  links: "/road-map",
 },
 {
  id: 7,
  name: "About",
  links: "/about",
 },
];

export default menus;
