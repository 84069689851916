import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./App.scss";
import ScrollToTop from "./ScrollToTop";
import { loadEnv } from "./env";

const root = ReactDOM.createRoot(document.getElementById("root"));
loadEnv().then(() =>
 root.render(
  <React.Fragment>
   <BrowserRouter>
    <ScrollToTop />
    <App />
   </BrowserRouter>
  </React.Fragment>
 )
);
