import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap-accordion";
import { useData } from "../hooks/useData.jsx";

import { Tab, TabList, TabPanel, Tabs } from "react-tabs";

Faqs.propTypes = {
 data: PropTypes.array,
};

function Faqs() {
 const { data: dataFaq } = useData({
  default: [],
  path: "/data/datafaq.json",
 });

 const [questions, setQuestions] = useState([]);

 useEffect(() => {
  if (dataFaq && dataFaq[0]) setQuestions(dataFaq[0].items);
 }, [dataFaq]);

 const [dataBlock] = useState({
  subheading: "FAQs",
  heading: "Frequently Aksed Questions",
  desc1:
   "Below are frequently asked questions when experiencing features at Thermal, we will answer related questions to bring understanding.",
  desc2: "Check out some of the answers below",
 });
 return (
  <section className="faq">
   <div className="shape right"></div>
   <div className="container">
    <div className="row">
     <div className="col-12">
      <div
       className="block-text center"
       data-aos="fade-down"
       data-aos-duration="3000"
      >
       <h6 className="sub-heading">
        <span>{dataBlock.subheading}</span>
       </h6>
       <h3 className="heading">{dataBlock.heading}</h3>
       <p className="mb-17">{dataBlock.desc1}</p>
       <p>{dataBlock.desc2}</p>
      </div>

      <div className="faq__main flat-tabs">
       <Tabs>
        <TabList className="menu-tab">
         {dataFaq.map((idx) => (
          <Tab
           onClick={() => setQuestions(idx.items)}
           className="fs-14 h6"
           key={idx.groupName}
          >
           {idx.groupName}
          </Tab>
         ))}
        </TabList>

        {questions.map((id) => (
         <TabPanel key={id} className="content-tab">
          <div className="content-inner">
           <div className="flat-accordion row">
            <div className="col-md-6 col-sm-12">
             {questions
              .slice(0, Math.ceil(questions.length / 2))
              .map((item) => (
               <Accordion
                show={""}
                key={item.title}
                title={item.title}
                className="flat-toggle h6"
               >
                <p className="toggle-content">{item.desc} </p>
               </Accordion>
              ))}
            </div>

            <div className="col-md-6 col-sm-12">
             {questions
              .slice(Math.ceil(questions.length / 2), questions.length)
              .map((item) => (
               <Accordion
                show={""}
                key={item.title}
                title={item.title}
                className="flat-toggle h6"
               >
                <p className="toggle-content">{item.desc} </p>
               </Accordion>
              ))}
            </div>
           </div>
          </div>
         </TabPanel>
        ))}
       </Tabs>
      </div>
     </div>
    </div>
   </div>
  </section>
 );
}

export default Faqs;
