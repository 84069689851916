import { useData } from "fuhi-web3ts";
import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Pagination from "../pagination/Pagination";

ProjectPagination.propTypes = {
 data: PropTypes.array,
};

function ProjectPagination() {
 const { data } = useData({ default: [], path: "/data/nfts/list.json" });
 const [currentPage, setCurrentPage] = useState(1);
 const [itemsPerPage] = useState(8);

 // Lấy dữ liệu hiện tại
 const currentData = useMemo(() => {
  const indexOfLastItem = currentPage * itemsPerPage,
   indexOfFirstItem = indexOfLastItem - itemsPerPage;
  return data.slice(indexOfFirstItem, indexOfLastItem);
 }, [currentPage, itemsPerPage, data]);

 // Thay đổi trang
 const paginate = (pageNumber) => setCurrentPage(pageNumber);
 return (
  <section className="nft">
   <div className="container">
    <div className="row">
     {currentData.map((idx) => (
      <div key={idx.marketId} className="col-xl-3 col-md-6">
       <div className="nft-item">
        <div className="card-media">
         <Link to="#">
          <img src={idx.image} alt="Thermal" />
         </Link>
        </div>
        <div className="card-title">
         <Link to="#" className="h5">
          {idx.name}
         </Link>
        </div>
        <div className="meta-info overflow-hidden">
         <div className="author overflow-hidden">
          <div className="avatar">
           <img src="/images/layouts/avt-01.png" alt="Thermal" />
          </div>
          <div className="info  overflow-hidden">
           <span>Owned by</span>
           <Link to="#" className="h6 truncate w-fit">
            {idx.owner}
           </Link>
          </div>
         </div>
        </div>
        <div className="card-bottom style-explode">
         <div className="price">
          <div className="price-details">
           <span> Current Bid</span>
           <h6>{idx.price} BNB</h6>
          </div>
         </div>
         <div className="button-place-bid">
          <a
           href="javascript:void(0)"
           className="sc-button"
           onClick={() => toast.info("Coming soon!")}
          >
           <span>Buy</span>
          </a>
         </div>
        </div>
       </div>
      </div>
     ))}
    </div>
    <Pagination
     itemsPerPage={itemsPerPage}
     totalItems={data.length}
     paginate={paginate}
     currentPage={currentPage}
    />
   </div>
  </section>
 );
}

export default ProjectPagination;
