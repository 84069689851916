import React, { useState } from "react";
import About from "../components/about/About";
import Banner from "../components/banner/Banner";
import Create from "../components/create/Create";
import Faqs from "../components/faqs/Faqs";
import Footer from "../components/footer/Footer";
import { useData } from "../components/hooks/useData.jsx";
import Portfolio from "../components/portfolio/Portfolio";
import Project from "../components/project/Project";
import Roadmap from "../components/roadmap/Roadmap";
import Speciality from "../components/speciality/Speciality";
import Team from "../components/team/Team";
import Testimonials from "../components/testimonials/Testimonials";

function Home01(props) {
 const { data: dataPortfolio } = useData({
  default: [],
  path: "/data/dataPortfolioHome.json",
 });
 const [dataBlock] = useState({
  subheading: "Private is Available Now",
  heading: "Own RMA tokens today through Private",
 });

 return (
  <div className="home-1 wrapper">
   <Banner />

   <About />

   <Speciality />

   <Portfolio data={dataPortfolio} dataBlock={dataBlock} />

   <Project />

   <Roadmap isViewFull />

   <Team />

   <Testimonials />

   <Faqs />

   <Create />

   <Footer />
  </div>
 );
}

export default Home01;
