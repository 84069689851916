import React from "react";

import Footer from "../components/footer/Footer";
import PageTitle from "../components/pagetitle/PageTitle";

import ProjectPagination from "../components/project/ProjectPagination";

function Nfts(props) {
 return (
  <div className="wrapper">
   <PageTitle title="NFTs" />

   <ProjectPagination></ProjectPagination>

   <Footer />
  </div>
 );
}

export default Nfts;
