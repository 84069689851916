import {
 faCopy,
 faFileImport,
 faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
 BuyPresaleProvider,
 NumberFormater,
 useAccount,
 useAddToken,
 useBuyPresale,
 useConnect,
 useContractAddress,
 useRefAddress,
 useWeb3Ts,
} from "fuhi-web3ts";
import { useMemo, useState } from "react";
import "./Buy.css";
import { ConnectWalletButton } from "./ConnectWalletButton";
import { FormInput } from "./FormInput";
export const BuyPageInner = () => {
 const { isConnected } = useConnect();

 return (
  <div className="container max-w-xl min-h-[calc(100vh-100px)] flex items-center justify-center">
   <div className="sandbox w-full">
    <h4 className="text-center">PRIVATE</h4>
    {isConnected ? <WalletConnected /> : <WalletDisconnect />}
   </div>
  </div>
 );
};
const WalletDisconnect = () => {
 return (
  <div className="flex justify-center">
   <ConnectWalletButton />
  </div>
 );
};
const WalletConnected = () => {
 const {
  fromValue,
  toValue,
  setFromValue,
  method,
  setMethod,
  balance,
  balanceToken,
  buy,
  isAllowBuy,
 } = useBuyPresale();
 const [isBuying, setIsBuying] = useState(false);
 const { config, buyMetadata } = useWeb3Ts();
 const { createInvite } = useRefAddress();
 const { account } = useAccount();
 const { addToken, isSupport } = useAddToken();
 const { address } = useContractAddress({ autoShort: true });
 const minBuy = useMemo(() => {
  return method === "bnb" ? buyMetadata.minBuy : buyMetadata.minBuyUSDT;
 }, [method, buyMetadata.minBuy, buyMetadata.minBuyUSDT]);
 const maxBuy = useMemo(() => {
  return method === "bnb" ? buyMetadata.maxBuy : buyMetadata.maxBuyUSDT;
 }, [method, buyMetadata.maxBuy, buyMetadata.maxBuyUSDT]);
 const icon = useMemo(() => {
  return method === "usdt" ? "/images/usdt.png" : "/images/bnb.png";
 }, [method]);

 const symbol = useMemo(() => {
  return method === "usdt" ? "USDT" : "BNB";
 }, [method]);
 const exchangeRate = useMemo(() => {
  return method === "bnb" ? buyMetadata.salePrice : buyMetadata.salePriceUSDT;
 }, [method, buyMetadata.salePrice, buyMetadata.salePriceUSDT]);
 const handleBuy = async () => {
  try {
   setIsBuying(true);
   await buy();
  } catch (error) {
   console.log(error);
  } finally {
   setIsBuying(false);
  }
 };

 return (
  <>
   <p className="text-center mb-30">
    RMA price for this round:{" "}
    <span className="price color-popup">
     1 {symbol} = {NumberFormater.format(exchangeRate)}{" "}
     {config.mainContract.symbol}
    </span>
   </p>

   <FormInput
    balance={balance}
    value={fromValue}
    onChange={setFromValue}
    placeholder={"0.00 " + symbol}
    icon={icon}
    symbol={symbol}
    onClickSymbol={() => setMethod(method === "usdt" ? "bnb" : "usdt")}
   />
   <div className="mb-20"></div>
   <p className="">Your receive</p>
   <FormInput
    balance={balanceToken}
    value={toValue}
    symbol={config.mainContract.symbol}
    placeholder={"0 " + config.mainContract.symbol}
   />
   <div className="mb-20"></div>
   <div className="hr" />
   <div className="flex flex-col gap-3">
    <div className="d-flex justify-content-between ">
     <p> Min buy:</p>
     <p className="text-right price color-popup">
      {" "}
      {NumberFormater.format(minBuy)} {symbol}
     </p>
    </div>
    <div className="d-flex justify-content-between ">
     <p> Max buy:</p>
     <p className="text-right price color-popup">
      {" "}
      {maxBuy} {symbol}{" "}
     </p>
    </div>
    <div className="d-flex justify-content-between ">
     <p> Referral code:</p>
     <div className="text-right flex  space-x-2">
      <p className=" price color-popup truncate">{account} </p>{" "}
      <FontAwesomeIcon
       title="Copy"
       icon={faCopy}
       className="cursor-pointer flex-1 w-full"
       onClick={createInvite}
      />
     </div>
    </div>
    <div className="d-flex justify-content-between ">
     <p> Token contract:</p>
     <div className="text-right flex  space-x-2">
      <p className=" price color-popup truncate">{address} </p>{" "}
      {isSupport && (
       <FontAwesomeIcon
        title="Import token"
        icon={faFileImport}
        className="cursor-pointer flex-1 w-full"
        onClick={addToken}
       />
      )}
     </div>
    </div>
   </div>
   <div className="flex justify-center">
    <button
     className="action-btn mt-10 disabled:opacity-50 disabled:cursor-not-allowed"
     disabled={!isAllowBuy || isBuying}
     onClick={handleBuy}
    >
     <span>
      Buy RMA{" "}
      {isBuying && (
       <FontAwesomeIcon className="animate-spin" icon={faSpinner} />
      )}
     </span>
    </button>
   </div>
  </>
 );
};
export const BuyPage = () => {
 return (
  <BuyPresaleProvider>
   <BuyPageInner />
  </BuyPresaleProvider>
 );
};
