import React from "react";

import BlogList from "../components/blog/BlogList";
import Footer from "../components/footer/Footer";
import PageTitle from "../components/pagetitle/PageTitle";
// import dataBlog from '../assets/fake-data/data-blog';
import { useData } from "../components/hooks/useData.jsx";

function Blog(props) {
 const { data: dataBlog } = useData({
  default: [],
  path: "/data/datablog.json",
 });
 return (
  <div className="wrapper">
   <PageTitle title="News" />

   <BlogList data={dataBlog} />

   <Footer />
  </div>
 );
}

export default Blog;
