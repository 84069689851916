import React, { useState } from "react";
import Button from "../button/Button";

function Create(props) {
 const [dataBlock] = useState({
  heading: "Get your own NFTs now",
  desc: "Enter the world of telecommunications with exciting and valuable NFTs",
 });
 return (
  <section className="create">
   <div className="container">
    <div className="row">
     <div className="col-12">
      <div className="create__main">
       <div className="content">
        <h4 className="heading">{dataBlock.heading}</h4>
        <p>{dataBlock.desc}</p>
        <Button title="Join Now" link="/nfts" />
       </div>
       <img src="/images/layouts/create.png" alt="Thermal" />
      </div>
     </div>
    </div>
   </div>
  </section>
 );
}

export default Create;
