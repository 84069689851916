import React, { useState } from "react";

import About2 from "../components/about/About2";
import Counter from "../components/counter/Counter";
import Footer from "../components/footer/Footer";
import PageTitle from "../components/pagetitle/PageTitle";
import Portfolio from "../components/portfolio/Portfolio";
import Team from "../components/team/Team";

import Button from "../components/button/Button";

import { useData } from "../components/hooks/useData.jsx";

function About(props) {
 const { data: dataPortfolio } = useData({
  default: [],
  path: "/data/dataPortfolioAbout.json",
 });
 const { data: dataTeam } = useData({
  default: [],
  path: "/data/dataTeam.json",
 });
 const [dataBlock] = useState({
  subheading: "Enter the NFT world",
  heading: "Become a professional collector with Thermal",
 });
 return (
  <div className="page-about wrapper">
   <PageTitle title="About us" />

   <About2 />

   <Counter />

   <Portfolio data={dataPortfolio} dataBlock={dataBlock} />

   <Team />

   <section className="create">
    <div className="container">
     <div className="row">
      <div className="col-12">
       <div className="create__main">
        <div className="content">
         <h4 className="heading">Get your own NFTs now</h4>
         <p>
          Enter the world of telecommunications with exciting and valuable NFTs
         </p>
         <Button title="Join Now" link="/nfts" />
        </div>
        <img src="/images/layouts/create.png" alt="Thermal" />
       </div>
      </div>
     </div>
    </div>
   </section>

   <Footer />
  </div>
 );
}

export default About;
