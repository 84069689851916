import React, { useState } from "react";

import Counter from "../components/counter/Counter";
import Faqs from "../components/faqs/Faqs";
import Footer from "../components/footer/Footer";
import PageTitle from "../components/pagetitle/PageTitle";

function VisionsMission(props) {
 const [dataImg] = useState([
  {
   id: 1,
   img: "/images/layouts/about-01.png",
   class: "img1",
  },
  {
   id: 2,
   img: "/images/layouts/about-02.png",
   class: "img2",
  },
  {
   id: 3,
   img: "/images/layouts/about-03.png",
   class: "img3",
  },
  {
   id: 4,
   img: "/images/layouts/about-04.png",
   class: "img4",
  },
  {
   id: 5,
   img: "/images/layouts/about-05.png",
   class: "img5",
  },
 ]);
 return (
  <div className="page-visions wrapper">
   <PageTitle title="Visions & Mission" />
   <section className="about">
    <div className="container">
     <div className="row">
      <div className="col-xl-6 col-md-12">
       <div className="block-text">
        <h6 className="sub-heading">
         <span>Visions & Mission</span>
        </h6>
        <h3 className="heading wow" data-splitting>
         Vision
        </h3>
        <p className="mb-17">
         Thermal aims to become a pioneering technology platform, eading the
         combination of Blockchain, 6G, NFT and Artificial Intelligence. We
         envision a world where limitless connectivity, ultimate security, and
         superior intelligence of distributed systems enable unprecedented
         experiences, driving innovation and inclusive progress.
        </p>
       </div>
       <div className="block-text">
        <h6 className="sub-heading">
         <span>Visions & Mission</span>
        </h6>
        <h3 className="heading wow" data-splitting>
         Mission
        </h3>
        <p>
         Thermal's mission is to create a breakthrough technology ecosystem,
         integrating the power of Blockchain to provide smart, secure and
         efficient connectivity and application solutions. We are committed to
         accelerating the development of innovative technologies, delivering
         real value to users and businesses, and building a sustainable and
         applicable digital future.
        </p>
       </div>
      </div>
      <div className="col-xl-6 col-md-12">
       <div className="about__right">
        <div className="images">
         {dataImg.map((idx) => (
          <img key={idx.id} className={idx.class} src={idx.img} alt="thermal" />
         ))}
        </div>
       </div>
      </div>
     </div>
    </div>
   </section>
   <Counter />

   <Faqs />

   <Footer />
  </div>
 );
}

export default VisionsMission;
