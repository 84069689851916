import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NumericFormat } from "react-number-format";
export const FormInput = ({
 icon,
 value,
 onChange,
 balance,
 placeholder,
 symbol,
 onClickSymbol,
}) => {
 return (
  <div className="">
   <div className="flex justify-end">
    <span className="text-xs">
     Balance: {balance} {symbol}
    </span>
   </div>
   <div className="relative ">
    <NumericFormat
     thousandSeparator
     placeholder={placeholder}
     value={value || ""}
     className="border border-[#20233f] rounded border-solid flex pl-4 py-2 focus:border-[#5c27fe]  w-full flex-1 bg-transparent outline-none text-white text-lg placeholder:text-white/80 pr-40"
     onValueChange={(e) => onChange?.(e.floatValue || 0)}
     disabled={!Boolean(onChange)}
    />
    {icon && symbol && (
     <div
      className="absolute right-4 top-1/2 -translate-y-1/2 flex items-center gap-x-3 justify-center cursor-pointer"
      onClick={onClickSymbol}
     >
      {icon && <img src={icon} className="w-4 h-4" alt="" />}
      {symbol && <span className="text-white ">{symbol}</span>}
      <FontAwesomeIcon icon={faChevronDown} />
     </div>
    )}
   </div>
  </div>
 );
};
