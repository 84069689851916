import React from 'react';
import PropTypes from 'prop-types';
import {useData} from "../hooks/useData";

PageTitle.propTypes = {

};

function PageTitle(props) {
    const { title } = props
    const { data: ENV } = useData({
        default: {
           socials: {
            x: '',
            telegram: '',
            email: '',
            github: '',
           },
          }, path: "/env.json" });
    return (
        <section className="page-title">
            <div className="shape"></div>
            <div className="shape right s3"></div>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="page-title__body">
                            <div className="page-title__main">
                                <h4 className="title">{title}</h4>

                                <ul className="breacrumb">
                                    <li><a href="/">Home</a></li>
                                    <li><p>Explore</p></li>
                                    <li><p>{title}</p></li>
                                </ul>
                                <div className="bottom">
                                    <p>Learn more about us</p>

                                    <ul className="list-social">
                                        <li><a target="_blank" href={ENV.socials.x}>
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 50 50" width="20px" height="20px">
                                                <path d="M 5.9199219 6 L 20.582031 27.375 L 6.2304688 44 L 9.4101562 44 L 21.986328 29.421875 L 31.986328 44 L 44 44 L 28.681641 21.669922 L 42.199219 6 L 39.029297 6 L 27.275391 19.617188 L 17.933594 6 L 5.9199219 6 z M 9.7167969 8 L 16.880859 8 L 40.203125 42 L 33.039062 42 L 9.7167969 8 z"
                                                    fill="white" /></svg>
                                        </a></li>
                                        <li><a target="_blank" href={ENV.socials.telegram}>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50"
                                                width="20px"
                                                height="20px">
                                                <path d="M46.137,6.552c-0.75-0.636-1.928-0.727-3.146-0.238l-0.002,0C41.708,6.828,6.728,21.832,5.304,22.445	c-0.259,0.09-2.521,0.934-2.288,2.814c0.208,1.695,2.026,2.397,2.248,2.478l8.893,3.045c0.59,1.964,2.765,9.21,3.246,10.758	c0.3,0.965,0.789,2.233,1.646,2.494c0.752,0.29,1.5,0.025,1.984-0.355l5.437-5.043l8.777,6.845l0.209,0.125	c0.596,0.264,1.167,0.396,1.712,0.396c0.421,0,0.825-0.079,1.211-0.237c1.315-0.54,1.841-1.793,1.896-1.935l6.556-34.077	C47.231,7.933,46.675,7.007,46.137,6.552z M22,32l-3,8l-3-10l23-17L22,32z"
                                                    fill="white" /></svg>
                                        </a></li>
                                        <li><a target="_blank" href={ENV.socials.email} >
                                            <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4 7.00005L10.2 11.65C11.2667 12.45 12.7333 12.45 13.8 11.65L20 7" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                <rect x="3" y="5" width="18" height="14" rx="2" stroke="white" stroke-width="2" stroke-linecap="round" />
                                            </svg>

                                        </a></li>
                                        <li><a target="_blank" href={ENV.socials.github}>
                                            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 0C5.374 0 0 5.373 0 12C0 17.302 3.438 21.8 8.207 23.387C8.806 23.498 9 23.126 9 22.81V20.576C5.662 21.302 4.967 19.16 4.967 19.16C4.421 17.773 3.634 17.404 3.634 17.404C2.545 16.659 3.717 16.675 3.717 16.675C4.922 16.759 5.556 17.912 5.556 17.912C6.626 19.746 8.363 19.216 9.048 18.909C9.155 18.134 9.466 17.604 9.81 17.305C7.145 17 4.343 15.971 4.343 11.374C4.343 10.063 4.812 8.993 5.579 8.153C5.455 7.85 5.044 6.629 5.696 4.977C5.696 4.977 6.704 4.655 8.997 6.207C9.954 5.941 10.98 5.808 12 5.803C13.02 5.808 14.047 5.941 15.006 6.207C17.297 4.655 18.303 4.977 18.303 4.977C18.956 6.63 18.545 7.851 18.421 8.153C19.191 8.993 19.656 10.064 19.656 11.374C19.656 15.983 16.849 16.998 14.177 17.295C14.607 17.667 15 18.397 15 19.517V22.81C15 23.129 15.192 23.504 15.801 23.386C20.566 21.797 24 17.3 24 12C24 5.373 18.627 0 12 0Z"
                                                    fill="white" />
                                            </svg>
                                        </a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default PageTitle;