import React, { useState } from "react";

import Button from "../button/Button";
import Partner from "../partner/Partner";

function About2(props) {
 const [dataBlock] = useState({
  subheading: "About us",
  heading: "We are here to change technology",

  desc1:
   "Thermal pioneers the development of 6G technology, combining the richness of NFTs, the powerful analytics of Artificial Intelligence (AI), and other advanced technologies at Blockchain",
  desc2:
   "With an innovative and forward-thinking vision, Thermal not only aims to create a superior global connectivity platform but also foster the development of decentralized applications, from digital art to the Internet of Things (IoT). The project aims to build a future technology ecosystem where speed, security and flexibility are pushed to new heights, opening up unlimited possibilities for users and businesses around the world.",
 });
 return (
  <section className="about s2">
   <div className="shape"></div>
   <div className="shape right"></div>
   <div className="container">
    <div className="row">
     <div className="col-12">
      <div className="block-text center pd-0">
       <h6 className="sub-heading">
        <span>{dataBlock.subheading}</span>
       </h6>
       <h3 className="heading pd">{dataBlock.heading}</h3>
      </div>

      <div
       className="about__main center"
       data-aos="fade-up"
       data-aos-duration="3000"
      >
       <img src="/images/layouts/about-06.png" alt="Thermal" />
       <p className="mb-17">{dataBlock.desc1}</p>
       <p className="mb-30">{dataBlock.desc2}</p>
       <Button title="More About Us" link="/whitepaper" />
      </div>

      <Partner />
     </div>
    </div>
   </div>
  </section>
 );
}

export default About2;
