import React from "react";

import Faq3 from "../components/faqs/Faq3";
import Footer from "../components/footer/Footer";
import PageTitle from "../components/pagetitle/PageTitle";
import Project2 from "../components/project/Project2";
import Roadmap from "../components/roadmap/Roadmap";

function RoadMap(props) {
 return (
  <div className="page-roadmap wrapper">
   <PageTitle title="Road Map" />

   <Roadmap />

   <Project2 />

   <Faq3 />
   <Footer />
  </div>
 );
}

export default RoadMap;
