import React from "react";

import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import { Link } from "../Link";
import { useData } from "../hooks/useData";
function Partner() {
 const { data } = useData({ default: [], path: "/data/partners.json" });
 return (
  <div
   className="brands block-text center"
   data-aos="fade-up"
   data-aos-duration="2000"
  >
   <div className="block-text center">
    <h3 className="heading">Partner & Investors </h3>
   </div>

   <div className="flex-wrap gap-4 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
    {data.map((idx) => (
     <div className="w-[185px]" key={idx.id}>
      <Link to={data.url}>
       <img src={idx.logo} alt="" />
      </Link>
     </div>
    ))}
   </div>
  </div>
 );
}

export default Partner;
