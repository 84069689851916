import { useEffect, useState } from "react";

export function useData(props) {
 const [data, setData] = useState(props.default);
 useEffect(() => {
  fetch(props.path, props.init)
   .then((t) => t.json())
   .then((resp) => {
    if (props.map) {
     resp = props.map(resp);
    }
    setData(resp);
   });
 }, []);
 return { data };
}
