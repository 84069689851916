import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap-accordion";

import { Tab, TabList, TabPanel, Tabs } from "react-tabs";

import { useData } from "../hooks/useData.jsx";

Faq3.propTypes = {
 data: PropTypes.array,
};

function Faq3() {
 // const { data } = props;
 const { data: dataFaq } = useData({
  default: [],
  path: "/data/datafaq.json",
 });

 const [questions, setQuestions] = useState([]);
 useEffect(() => {
  if (dataFaq && dataFaq[0]) setQuestions(dataFaq[0].items);
 }, [dataFaq]);

 const [dataBlock] = useState({
  subheading: "FAQs",
  heading: "Frequently Aksed Questions",
  desc:
   "Below are frequently asked questions when experiencing features at Thermal, we will answer related questions to bring understanding. Check out some of the answers below.",
 });

 return (
  <section className="faq s3">
   <div className="container">
    <div className="row">
     <div className="col-xl-6 col-md-12">
      <div className="block-text">
       <h6 className="sub-heading">
        <span>{dataBlock.subheading}</span>
       </h6>
       <h3 className="heading">{dataBlock.heading}</h3>
       <p>{dataBlock.desc}</p>
      </div>
      <div className="faq__main flat-tabs">
       <Tabs>
        <TabList className="menu-tab">
         {dataFaq.map((idx) => (
          <Tab
           onClick={() => setQuestions(idx.items)}
           className="fs-14 h6"
           key={idx.groupName}
          >
           {idx.groupName}
          </Tab>
         ))}
        </TabList>

        {questions.map((id) => (
         <TabPanel key={id} className="content-tab">
          <div className="content-inner">
           <div className="flat-accordion">
            {questions.map((item) => (
             <Accordion
              show={""}
              key={item.title}
              title={item.title}
              className="flat-toggle h6"
             >
              <p className="toggle-content">{item.desc} </p>
             </Accordion>
            ))}
           </div>
          </div>
         </TabPanel>
        ))}
       </Tabs>
      </div>
     </div>

     <div className="col-xl-6 col-md-12">
      <div className="image" data-aos="fade-left" data-aos-duration="2000">
       <img src="/images/layouts/img-faq.png" alt="Thermal" />
      </div>
     </div>
    </div>
   </div>
  </section>
 );
}

export default Faq3;
