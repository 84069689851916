import { useData } from "fuhi-web3ts";
import { useEffect, useMemo, useState } from "react";
import "./clock.css";
export const Clock = () => {
 const { data: ENV } = useData({
  default: {
   countdown: {
    year: 0,
    month: 0,
    day: 0,
    hour: 0,
    minute: 0,
   },
  },
  path: "/env.json",
 });
 const [value, setValue] = useState({});

 useEffect(() => {
  let interval;
  const date = new Date(
   ENV.countdown.year,
   ENV.countdown.month - 1,
   ENV.countdown.day,
   ENV.countdown.hour,
   ENV.countdown.minute
  );
  if (date.getTime() > Date.now()) {
   interval = setInterval(() => {
    const distance = date.getTime() - Date.now(),
     day = Math.floor(distance / (1000 * 60 * 60 * 24)),
     hour = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
     minute = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
     second = Math.floor((distance % (1000 * 60)) / 1000);
    setValue({
     day,
     hour,
     minute,
     second,
    });
   }, 1000);
  }
  return () => {
   if (interval) clearInterval(interval);
  };
 }, [ENV]);
 return (
  <div className="flex gap-x-4">
   <Item text="Days" value={value.day} />
   <Item text="Hours" value={value.hour} />
   <Item text="Minutes" value={value.minute} />
   <Item text="Seconds" value={value.second} />
  </div>
 );
};

const Item = ({ value, text }) => {
 const [value1, value2] = useMemo(() => {
  if (value == undefined || value === null) return ["-", "-"];
  if (value < 10) {
   return [0, value];
  }
  const vText = value + "";
  return vText;
 }, [value]);
 return (
  <div className="clock-item">
   <div className="step absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2">
    {text}
   </div>
   <h5 className="title">
    {value1}
    {value2}
   </h5>
  </div>
 );
};
