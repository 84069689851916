import React from "react";
import { isMobile } from "react-device-detect";

function Project2(props) {
 return (
  <section className="project s2">
   <div className="shape right"></div>
   <div className="container">
    <div className="row">
     <div className="col-12">
      <div className="block-text center">
       <h6 className="sub-heading">
        <span>Our Speciality</span>
       </h6>
       <h3 className="heading ">TOKENOMICS</h3>
      </div>
      <div>
       <div className="w-fit mx-auto project-box flex justify-center ">
        <div className="image">
         <img
          className="w-full mw-100"
          src={`${
           isMobile ? "/images/tokenomics-sm.png" : "/images/tokenomics.png"
          }`}
          alt="Thermal"
         />
        </div>
       </div>
      </div>
     </div>
    </div>
   </div>
  </section>
 );
}

export default Project2;
