import React from "react";

import Faqs from "../components/faqs/Faqs";
import Footer from "../components/footer/Footer";
import PageTitle from "../components/pagetitle/PageTitle";
import Partner from "../components/partner/Partner";

import { Link } from "react-router-dom";

import { useData } from "../components/hooks/useData.jsx";

function AdvisorsBackers(props) {
 const { data: dataTeam } = useData({
  default: [],
  path: "/data/dataAdvisor.json",
 });

 return (
  <div className="page-advisor wrapper">
   <PageTitle title="Advisors & Backers" />

   <section className="team s2">
    <div className="container">
     <div className="row justify-content-center">
      <div className="col-12">
       <div className="block-text center">
        <h3 className="heading">Advisors</h3>
       </div>
      </div>

      {dataTeam.map((idx) => (
       <div key={idx.id} className="col-xl-3 col-md-6">
        <div className="team-box">
         <div className="image">
          <Link to="/team">
           <img src={idx.img} alt="Thermal" />
          </Link>
         </div>
         <div className="content">
          <Link to="/team" className="h5 name">
           {idx.name}
          </Link>
          <p className="postion">{idx.position}</p>
         </div>
        </div>
       </div>
      ))}

      <Partner />
     </div>
    </div>
   </section>

   <Faqs />

   <Footer />
  </div>
 );
}

export default AdvisorsBackers;
